import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { injectIntl, Link, FormattedMessage, FormattedHTMLMessage, } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'


const TechnologyPage = ({data, intl}) => {
  
  const { tech } = data
   
  
  const techData = get(tech, 'edges', []).map(x => x.node)
  
  return (
  <Layout>
    <SEO title="Technology - INMAGIK" />
    <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">
      <div className="display-3 font-200 text-white">
        <FormattedMessage id="technology">Technology</FormattedMessage>
      </div>
      {/* <h1 className="display-4">INMAGIK</h1> */}
      <div className="row my-4">
        <div className="col-md-6 offset-md-3">
          <p className="lead font-200 text-white">
            <FormattedMessage id="technology_title">
              Our solutions are based on popular open source software, tools, frameworks and libraries, open protocols and web standards.
            </FormattedMessage>
            
          </p>
        </div>
      </div>

    </div>

    <div className="main-content container py-5">
      <div className="row my-3 justify-content-center">
        { techData.map((tech, i) => (<div key={i} className="col-md-4 p-1 text-center">
          <div className="p-4 text-center">
          {tech.icon && <img className="tech-icon mb-2" src={tech.icon.publicURL} alt={tech.title}></img>}
          <p className="display-small font-300 mb-0">
            {tech.title}
          </p>
          <p className="lead font-200" dangerouslySetInnerHTML={{ __html: tech.description }}
          ></p>
        </div>
        </div>))}
      </div>
      

    </div>

    
     

 
 
   
  </Layout>
)}

export default injectIntl(TechnologyPage)

export const query = graphql`
  query {
    tech: allTechYaml {
      edges {
        node {
          title
          description
          home
          icon {
            publicURL
          }
        }
      }
    }
     
  }
`